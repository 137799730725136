
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {registerUser} from "../../services/User/index"
import { toast } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './form.css';

const Signup_form = ({heading})=>{

    const [inputValues, setInputValues] = useState({ 
        name:'',
        email: '', 
        password: '', 
        confirmPassword: '' 
      });
    const [btnClicked, setBtnClicked] = useState(false)
    const navigate = useNavigate()


    const clearInputValues = () => {
        setInputValues({ companyName: '', 
        name: '',
        email: '', 
        password: '', 
        confirmPassword: '' })
    }
    const [createdUser,setCreatedUser]=useState(false)

    const [inputErrors, setInputErrors] = useState({
        name: { hasError: false, errorMessage: '' },
        email: { hasError: false, errorMessage: '' },
        password: { hasError: false, errorMessage: '' },
        confirmPassword: { hasError: false, errorMessage: '' }
      });

    const validateInput = (name, value) => {
        // perform validation and return error message if there's an error, otherwise return null
        

        if (name === 'name' && value!=='' && !/^[a-zA-Z ]+$/.test(value)) {
            return 'First name should only contain letters and spaces.';
          }
        if (name === 'name' && value=='') {
            return 'First name is required';
        }
        if (name === 'email') {
            if (value.trim() === '') {
              return 'Email is required.';
            }
            else if (!/^\S+@\S+\.\S+$/.test(value)) {
              return 'Email is invalid.';
            }
        }
        if (name === 'password' && value.trim() === '') {
        return 'Password is required.';
        }
        
        if (name === 'password' && value.length < 8) {
        return 'Password should be at least 8 characters long.';
        }
        
        if (name === 'confirmPassword' && value.trim() === '') {
        return 'Confirm Password is required.';
        }
        
        if (name === 'confirmPassword' && value !== inputValues.password) {
        return 'Confirm Password does not match Password.';
        }
        
        // add more validation checks for other fields here
        
        return null;
    }


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        
        // validate input and set error state
        const errorMessage = validateInput(name, value);
        setInputErrors(prevState => ({
          ...prevState,
          [name]: {
            hasError: Boolean(errorMessage),
            errorMessage: errorMessage || ''
          }
        }));
        
        // update input value
        setInputValues(prevState => ({
          ...prevState,
          [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(btnClicked)
        if (btnClicked)
            return
        setBtnClicked(true)

        const errors = {};
        Object.keys(inputValues).forEach((name) => {
        const error = validateInput(name, inputValues[name]);
        if (error) {
            errors[name] = { hasError: true, errorMessage: error };
        } else {
            errors[name] = { hasError: false, errorMessage: '' };
        }
        });
        setInputErrors(errors);

        let hasError = false;
        Object.keys(errors).forEach((name) => {
        if (errors[name].hasError) {
            hasError = true;
        }
        });

        if (hasError) {
            // There is at least one error
            setBtnClicked(false);
            return;
          }

        try {
            const resp = await registerUser(inputValues)
            if (resp.success) {
                clearInputValues()
                toast.success('User registered successfully')
                setCreatedUser(true)
                console.log(resp)
                setTimeout(() => {
                    navigate('/')
                }, 1500)
            }
            else
                throw new Error(resp.error)
        } catch (error) {
            console.log(error.message)
            if (error.message==="A user with this email already exits"){
                setInputErrors(prevState => ({
                    ...prevState,
                    email: {
                        ...prevState.email,
                        hasError: true,
                        errorMessage: error.message
                    }
                }))
            }
        }
        setBtnClicked(false)
        
    }


    return(
        <div className = "form-sec">
            <Form onSubmit={handleSubmit}>
                <div className = "form-heading text-center">
                   {heading}
                </div>
                
                

                <Form.Group className = "form-group">
                <Form.Label>Name</Form.Label>
                <Form.Control 
                    type = "text" 
                    placeholder = "Enter Name"
                    name="name"
                    value={inputValues.name}
                    onChange={handleInputChange}
                    className={inputErrors.name.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                    {inputErrors.name.hasError && inputErrors.name.errorMessage+'❌'}
                </Form.Control.Feedback>
                </Form.Group>
            

                <Form.Group className = "form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    type = "email" 
                    placeholder = "Enter Email"
                    name="email"
                    value={inputValues.email}
                    onChange={handleInputChange}
                    className={inputErrors.email.hasError ? "is-invalid" : ""}
                />
                
                <Form.Control.Feedback type="invalid">
                        {inputErrors.email.hasError && inputErrors.email.errorMessage+'❌'}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className = "form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                    type = "password" 
                    placeholder = "Enter Password"
                    name="password"
                    value={inputValues.password}
                    onChange={handleInputChange}
                    className={inputErrors.password.hasError ? "is-invalid" : ""}
                />
                
                <Form.Control.Feedback type="invalid">
                        {inputErrors.password.hasError && inputErrors.password.errorMessage+'❌'}
                    </Form.Control.Feedback>

                </Form.Group>
                <Form.Group className = "form-group">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control 
                    type = "password" 
                    placeholder = "Submit Password"
                    name="confirmPassword"
                    value={inputValues.confirmPassword}
                    onChange={handleInputChange}
                    className={inputErrors.confirmPassword.hasError ? "is-invalid" : ""}
                />
                
                <Form.Control.Feedback type="invalid">
                        {inputErrors.confirmPassword.hasError && inputErrors.confirmPassword.errorMessage+'❌'}
                    </Form.Control.Feedback>
                </Form.Group>

                {createdUser && <Form.Group className = "text-center last-pra" style={{marginBottom:'5px',color:'green'}}>
                    User registered successfully ✔
                </Form.Group>}
                <Form.Group className = "form-group">
                    <Button className = "btn btn-main" type = "submit">
                        Sign up
                    </Button>
                </Form.Group>
                <Form.Group className = "text-center last-pra">
                Already have an account? <Link to = "/login">Sign in</Link>
                </Form.Group>
            </Form>
        </div>
    );
}

export default Signup_form;