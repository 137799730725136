import React,{ Fragment } from "react";
import { ToastContainer, toast } from 'react-toastify';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main-style.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css'


import Router from "./Router";

function App() {
  return (
    <>
      <Router/>
      <ToastContainer className={'toast-position'} />
    </>
  );
}

export default App;
