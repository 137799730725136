
import React, { useEffect, useState, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";

import Send_icon from '../../images/icons/send_ic.svg';
import Hajira_img from '../../images/hajira.png';
import Muzamil_img from '../../images/muzumil.png';
import Rabbia_img from '../../images/rabbia.png';
import Chat_img from '../../images/chat-img.png';
import Groupcontact from '../group-table/groupcontact';

import Userlist from './userlist';

import "./inbox.css";

const Inboxchat = () => {

    const [dtshow, setDtShow] = useState(false);
    const [showMessage,setMessage] = useState(window.innerWidth > 768)
    const [cgtshow, setCgtShow] = useState(false);
    const [ctshow, setCtShow] = useState(false);
    const [muteshow, setMuteShow] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const refOne = useRef(null);
    const refTwo = useRef(null);

    const buttonClick = () => {
        setIsActive(true);
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };   


    useEffect(() => {
        if (refTwo.current){
            refTwo.current.scrollTop = 20000000; // px
        }
     }, [refTwo.current]);


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    
      const handleClickOutside = (event) => {
        if (refOne.current && !refOne.current.contains(event.target)) {
            setIsActive(false);
            setInputValue("");
        }
      };

      useEffect(() => {
        const handleResize = () => {
            setMessage(window.innerWidth > 767);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
      const handleButtonClick = () => {
        if (window.innerWidth < 768) {
            setMessage(true);
        }     

    }

    return (
        <div className = "chat-section">
            <Row className = "mx-0">
                <Col md = {4} xxl = {3}>
                    <div className = "user-list h-100">
                        <div className = "user-list-header">
                            <Row className = "mx-0">
                                <Col xs = {12} md = {12} className = "position-relative px-0">
                                    <Button variant = "main" onClick={()=>setCgtShow(true)}>
                                        New Message
                                    </Button>
                                    <input ref = {refOne} type = "text" value = {inputValue}  className = {isActive ? "active search" : "search"} onClick = {buttonClick} 
                                    onChange={handleInputChange}/>
                                </Col>
                            </Row>
                        </div>
                       { <div className = "active-user-list">
                            <Userlist Image = {Hajira_img} handleButtonClick={handleButtonClick} Name = {'Hajira Ihsan'} Messagetitle = {'Hi, there how are you?'} Time = {'9:17 pm'}/>

                            <Userlist Image = {Muzamil_img} handleButtonClick={handleButtonClick} Name = {' Muzamil Khan'} Messagetitle = {'Hi, there how are you?'} Time = {'9:17 pm'}/>

                            <Userlist Image = {Rabbia_img} handleButtonClick={handleButtonClick} Name = {'Rabia Awan'} Messagetitle = {'Hi, there how are you?'} Time = {'9:17 pm'}/>

                            <Userlist Image = {Hajira_img} handleButtonClick={handleButtonClick} Name = {'Anum Ihsan'} Messagetitle = {'Hi, there how are you?'} Time = {'9:17 pm'}/>
                        </div>}
                    </div>
                </Col>

                { showMessage && ( 
                    <Col md = {5} xxl = {6}>
                        <div className = "live-chat-sec h-100">
                            <div className = "chat-header">
                                <ul className = "nav user-info">
                                    <li className = "nav-item d-flex d-md-none">
                                        <div className = "back-btn" onClick={()=>{
                                            setMessage(false)
                                        }}>
                                            <i className ="bi bi-arrow-left-circle-fill"></i>
                                        </div>
                                    </li>
                                    <li className = "nav-item">
                                        <div className = "user-image">
                                            <img src = {Hajira_img} alt = "user-img"/>
                                        </div>
                                    </li>
                                    <li className = "nav-item">
                                        <div className = "user-name">
                                            Hajira Ihsan
                                        </div>
                                    </li>
                                </ul>
                                <div className = "chat-option">
                                    <Dropdown>
                                        <Dropdown.Toggle className = "chat-o-btn">
                                            <i className ="bi bi-three-dots-vertical"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className = "option-dropdown">
                                            <Dropdown.Item onClick={() => setMuteShow(true)}>
                                                Mute notifications
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setDtShow(true)}>
                                                Delete chat
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                Block
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div ref={refTwo} className = "chat-message-sec">
                                <div className = "msg-row received row mx-0">
                                    <div className = "mesg-sec received">
                                        Hi, are you available?
                                        <div className = "time-area">
                                            9:17 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        When are we conducting the event kindly send the exact date and time. Thank you.
                                        <div className = "time-area">
                                            9:18 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        Hi, are you available?
                                        <div className = "time-area">
                                            9:17 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        When are we conducting the event kindly send the exact date and time. Thank you.
                                        <div className = "time-area">
                                            9:18 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        Hi, are you available?
                                        <div className = "time-area">
                                            9:17 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        When are we conducting the event kindly send the exact date and time. Thank you.
                                        <div className = "time-area">
                                            9:18 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        Hi, are you available?
                                        <div className = "time-area">
                                            9:17 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec received">
                                        When are we conducting the event kindly send the exact date and time. Thank you.
                                        <div className = "time-area">
                                            9:18 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec send">
                                        <img src = {Chat_img} alt = "chat-img"/>
                                        Hello everyone.
                                        <div className = "time-area">
                                            9:26 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec send">
                                        yes, available.
                                        <div className = "time-area">
                                            9:20 pm
                                        </div>
                                    </div>
                                    <div className = "mesg-sec send">
                                        Sure, will send you in a while.
                                        <div className = "time-area">
                                            9:25 pm
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className = "write-msg-sec">
                                <div className = "row mx-0">
                                    <Col sm = {12} className = "px-0">
                                        <div className = "attached-file">
                                            <Dropdown>
                                                <Dropdown.Toggle className = "attached-btn">
                                                    <i className="bi bi-paperclip"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className = "attached-option">
                                                    <Dropdown.Item>
                                                        <ul className = "nav align-items-center">
                                                            <li className = "nav-item">
                                                                <div className = "link-icon">
                                                                    <i className="bi bi-camera"></i>
                                                                </div>
                                                            </li>
                                                            <li className = "nav-item">
                                                                Capture
                                                            </li>
                                                        </ul>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <ul className = "nav align-items-center">
                                                            <li className = "nav-item">
                                                                <div className = "link-icon">
                                                                    <i className="bi bi-file-earmark"></i>
                                                                </div>
                                                            </li>
                                                            <li className = "nav-item">
                                                                Document
                                                            </li>
                                                        </ul>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <ul className = "nav align-items-center">
                                                            <li className = "nav-item">
                                                                <div className = "link-icon">
                                                                    <i className="bi bi-person"></i>
                                                                </div>
                                                            </li>
                                                            <li className = "nav-item">
                                                                Contact
                                                            </li>
                                                        </ul>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <ul className = "nav align-items-center">
                                                            <li className = "nav-item">
                                                                <div className = "link-icon">
                                                                    <i className="bi bi-image"></i>
                                                                </div>
                                                            </li>
                                                            <li className = "nav-item">
                                                                Gallery
                                                            </li>
                                                        </ul>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <ul className = "nav align-items-center">
                                                            <li className = "nav-item">
                                                                <div className = "link-icon">
                                                                    <i className="bi bi-envelope-paper"></i>
                                                                </div>
                                                            </li>
                                                            <li className = "nav-item">
                                                                Send Template
                                                            </li>
                                                        </ul>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className = "text-input">
                                            <input type = "text"/>
                                        </div>
                                        <div className = "command-sec">
                                            <ul className = "nav align-items-center">
                                                <li className = "nav-item">
                                                    <Button className = "send-btn">
                                                        <img src = {Send_icon} alt = "icon"/>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
                )}

                <Col md = {3} xxl = {3} className = "d-none d-md-block">
                    <div className = "user-info-data h-100">
                        <div className = "user-info-header">
                            <div className = "user-name">Hajira Ihsan</div>
                            <div className = "online-status">Available <span></span></div>
                        </div>
                        <div className = "user-personal-info">
                            <div className = "info-row">
                                <div className = "title">Basic Information</div>
                                <div className = "description">Phone number: 03025676625</div>
                            </div>
                            <div className = "info-row">
                                <div className = "title">About</div>
                                <div className = "description">Company: MWAN MOBILE</div>
                                <div className = "description">Designation: UX/UI Designer</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal show={cgtshow} onHide={() => setCgtShow(false)} className = "whatsapp-modal modal-g-contact modal-select-contact" centered>
                <Modal.Header className = "p-0" closeButton>
                    <Modal.Title>
                        New Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Control type = "text" placeholder = "Search Contact"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className = "mx-0">
                        <div className = "col-12 contact-col">
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Hajira_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Muzamil_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Rabbia_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Hajira_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                            <Row className = "mb-2 contact-group">
                                <Col sm = {12}>
                                    <div className = "user-name">
                                        <Groupcontact Image = {Rabbia_img} name = {'Ava Brown'}/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <div className = "attention-msg">
                        Couldn’t find contact in the list?
                    </div>
                    <Button  variant = "main-default" onClick={() => {setCgtShow(false) 
                        setCtShow(true)}}>
                        Add New Contact
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ctshow} onHide={() => setCtShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Add Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type = "text" placeholder = "Enter name"/>
                            </Form.Group>
                        </Col>
                        <Col sm = {6}>
                            <Form.Group>
                                <Form.Label>Phone No</Form.Label>
                                <Form.Control type = "text" placeholder = "Ex: 923025676625"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type = "email" placeholder = "Enter email"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm = {12}>
                            <Button className = "import-cvs">
                                Import Contacts From CSV File
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setCtShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setCtShow(false)}>
                        Add Contact
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={muteshow} onHide={() => setMuteShow(false)} className = "whatsapp-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Mute “Hajira” for ...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs = {12} className = "mb-3">
                            <div className = "radio-row d-flex align-items-center">
                                <input name = "radio" type = "radio" className = "form-check-input mt-0" id = "8hours"/>
                                <Form.Label htmlFor = "8hours" className = "mb-0 ms-2">8 hours</Form.Label>
                            </div>
                        </Col>
                        <Col xs = {12} className = "mb-3">
                            <div className = "radio-row d-flex align-items-center">
                                <input name = "radio" type = "radio" className = "form-check-input mt-0" id = "1week"/>
                                <Form.Label htmlFor = "1week" className = "mb-0 ms-2">1 Week</Form.Label>
                            </div>
                        </Col>
                        <Col xs = {12} className = "mb-3">
                            <div className = "radio-row d-flex align-items-center">
                                <input  name = "radio" type = "radio" className = "form-check-input mt-0" id = "always"/>
                                <Form.Label htmlFor = "always" className = "mb-0 ms-2">Alwasy</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button className = "btn-main-default" onClick={()=>setMuteShow(false)}>
                        Cancel
                    </Button>
                    <Button  className = "btn-main" onClick={()=>setMuteShow(false)}>
                        Mute Notification
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={dtshow} onHide={() => setDtShow(false)} className = "whatsapp-modal confirm-modal modal-contact" centered>
                <Modal.Header className = "p-0">
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className = "text-content">
                        You are about to delete <span className = "u-name">“ Fahad “</span> from your contacts. This process cannot be reversed.
                    </div>
                </Modal.Body>
                <Modal.Footer className = "px-0">
                    <Button variant = "danger-light" onClick={() => setDtShow(false)}>
                        Yes, Delete it
                    </Button>
                    <Button  variant = "danger" onClick={() => setDtShow(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Inboxchat;