import { useEffect, useState, useContext } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getKycCredentials,getKycDetails } from '../services/User';
import UserContext from '../store/user-context';

function DoKyc() {
  const navigate = useNavigate();
  const [kycWebsite, setKycWebsite] = useState('');
  const [accessKey, setAccessKey] = useState('');
  const { userState: { email },setUserState } = useContext(UserContext);
  const [payload, setPayload] = useState(null);
  const [lastKycInfo,setLastKycInfo]=useState(null)

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:4003'); // Replace with your backend WebSocket server URL

    // Handle incoming WebSocket messages
    socket.onmessage = (event) => {
      const receivedPayload = JSON.parse(event.data);
      setPayload(receivedPayload);
      console.log(receivedPayload)
    };

    // Clean up the WebSocket connection on unmount
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      const resp = await getKycCredentials();
      if (resp.success) {
        setKycWebsite(resp.kycUrl);
        setAccessKey(resp.accessKey);
      }
      console.log(resp);
    };

    getData();
  }, []);

  const handleClickStartKyc = () => {
    if (kycWebsite.length > 0 && accessKey.length > 0) {
      const url = `${kycWebsite}?email=${email}&accesskey=${accessKey}`;
      window.open(url, '_blank');
    }
  };

  console.log(payload)


  useEffect(() => {
    const getData=async ()=>{
      const resp=await getKycDetails(email)
      if(resp.success){
        setLastKycInfo(resp.data)
      }
    }

    getData()
  }, [payload]);


  console.log(lastKycInfo)

  const goNext=()=>{
      setUserState((prev) => ({
        ...prev,
        kycCompleted: true
      }));
      localStorage.setItem('kycCompleted', true)
      navigate('/')
  }

  const style1={
    backgroundColor:'Red',
    color:'white'
  }
  const style2={
    backgroundColor:'lightgreen',
    color:'black'
  }

  return (
    <>
      <section className="wahtsapp_form pt-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={10} lg={8} xl={7} xxl={6}>
              <div className="accessfacebook-content bg-white">
                <div className="text-center access-img mb-3 mb-lg-4"></div>
                <h2 className="text-center">
                  YOU NEED TO COMPLETE THE KYC PROCESS TO CONTINUE
                </h2>
                <p className="top">
                  To continue, you will need to go to our KYC service provider's website by clicking the '<a href="#">START KYC</a>' button below you require:
                </p>
                <ul>
                  <li>
                    10 mins of time to complete the process.
                  </li>
                  <li>
                    A valid Document ID containing your picture (Passport, National ID).
                  </li>
                  <li>
                    A working Webcam that will be used to take your live picture.
                  </li>
                </ul>

                {lastKycInfo!==null && lastKycInfo.message.length>0 ? 
                <div className = "highlighted-pra" style={lastKycInfo.status?style2:style1}>
                      <p>
                        <b>KYC Status: {lastKycInfo.status? "Successful":"Failed"} </b>
                          <br/><br/>
                          {lastKycInfo.message}
                      </p>
                </div>  : <></>}

                {lastKycInfo===null || lastKycInfo.status===false ? <button
                  disabled={kycWebsite.length === 0 || accessKey.length === 0}
                  className="btn"
                  onClick={handleClickStartKyc}
                >
                  START KYC
                </button>:
                <button onClick={goNext} className="btn">
                  CONTINUE
                </button>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {payload && (
        <div>
          <h2>Payload Data:</h2>
          <pre>{JSON.stringify(payload, null, 2)}</pre>
        </div>
      )}
    </>
  );
}

export default DoKyc;
