import React from 'react'
import Inboxchat from "../components/chat/inboxchat";


const Home = () => {
  return(
    <section className="main">
        <Inboxchat/>
    </section>
);
}

export default Home

