import React from 'react'
import { createContext, useState } from 'react'

const DataContext = createContext({
    socket: null, setSocket: () => { }
})

export function DataContextProvider({ children }) {
    const [socket, setSocket] = useState()
    const context = { socket, setSocket }
    return (
        <DataContext.Provider value={context}>
            {children}
        </DataContext.Provider>)
}

export default DataContext