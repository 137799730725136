
import React, {useState } from 'react';

const Userlist = ({Image,Name,Messagetitle,Time,handleButtonClick}) => {

    return (
        <div className = "single-user" onClick = {handleButtonClick}>
            <div className = "user-details">
                <div className = "user-image">
                    <img src = {Image} alt = "user-img"/>
                    <span className = "online-status"></span>
                </div>
                <div className = "user-content">
                    <div className = "user-name">
                        {Name}
                    </div>
                    <div className = "msg-title">
                        {Messagetitle}
                    </div>
                </div>
                <div className = "time-sec">
                    {Time}
                </div>
            </div>                                          
        </div>
    )
}

export default Userlist;