import React,{ Fragment } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'

import UserContext from './store/user-context'


import Header from "./components/header/Header";
import Login from "./pages/login";
import Signup from "./pages/signup";
import DoKyc from "./pages/do-kyc"
import Home from "./pages/Home";

import { DataContextProvider } from './store/data-context'
import { toast } from 'react-toastify'
import { Button, Container } from 'react-bootstrap'

function Router(){

    const { userState: { isAuth,kycCompleted }, setUserState } = useContext(UserContext)
    const [isLoaded, setIsLoaded] = useState(false)
    const navigate = useNavigate();

    const logOutBtnHandler = (showToast = false) => {
        if (showToast)
            // toast.success('User logged out successfully')
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        setUserState({ isAuth: false, token: null, userId: null, email: null,kycCompleted:false })
    }

    useEffect(() => {
        setIsLoaded(false)
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        const email = localStorage.getItem('email')
        const kycCompleted=localStorage.getItem('kycCompleted')
        if (!token || !userId) {
            logOutBtnHandler()
            setIsLoaded(true)
            return
        }
        setUserState({ isAuth: true, token: token, userId: userId ,email:email,kycCompleted:kycCompleted})
        setIsLoaded(true)
    }, [])

    return (
        <Fragment>
            
            {isLoaded? 
                <Routes>
                    
                    {
                        isAuth?
                            localStorage.getItem('kycCompleted')=="false"?
                                <Route path='/' element={<DataContextProvider><Header logOutBtnHandler={logOutBtnHandler} /></DataContextProvider>} >
                                    <Route path='/' element={<DoKyc />} />
                                </Route>
                                :
                                <Route path='/' element={<DataContextProvider><Header logOutBtnHandler={logOutBtnHandler} /></DataContextProvider>} >
                                    <Route path='/' element={<Home />} />
                                </Route>
                               :
                                <Route path='/' element={<Header loggedout={true}/>}>
                                    <Route path='/' element={<Login  />}>
                                    </Route>
                                    <Route path= "/login" element={<Login/>}/>
                                    <Route path= "/signup" element = {<Signup/>}/>
                                    
                                </Route> 
                    }
                    <Route
                        path='*'
                        element={
                            <div className='heading'>
                                <Container className='d-flex flex-column '>
                                    <h1>There's nothing here. You've taken a wrong turn!</h1>
                                    <Button className='w-25' style={{ fontSize: '1.5rem' }} onClick={() => navigate(-1)}>Go Back</Button>
                                </Container>
                            </div>
                        }
                    />
                </Routes>
            :
                <>
                </>
            }

        </Fragment>
    )
}

export default Router