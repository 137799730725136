import { useState,useContext } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {loginUser} from '../../services/User/index'

import UserContext from "../../store/user-context";


import './form.css';

const Whatsapp_form = ({heading})=>{

    const { setUserState } = useContext(UserContext);
    const [inputValues, setInputValues] = useState({ email: '', password: '' })
    const [inputErrors, setInputErrors] = useState({
      email: { hasError: false, errorMessage: '' },
      password: { hasError: false, errorMessage: '' }
    });

    const [btnClicked, setBtnClicked] = useState(false)
    const navigate = useNavigate()

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        const errorMessage = validateInput(name, value);
        setInputErrors(prevState => ({
          ...prevState,
          [name]: {
            hasError: Boolean(errorMessage),
            errorMessage: errorMessage || ''
          }
        }));
        setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
      };
    

    const clearInputValues = () => {
        setInputValues({ email: '', password: '' })
    }

    const validateInput = (name, value) => {
      // perform validation and return error message if there's an error, otherwise return null
      if (name === 'email') {
          if (value.trim() === '') {
            return 'Email is required.';
          }
          else if (!/^\S+@\S+\.\S+$/.test(value)) {
            return 'Email is invalid.';
          }
      }
      
      if (name === 'password' && value.trim() === '') {
      return 'Password is required.';
      }
      
      // add more validation checks for other fields here
      
      return null;
  }

    const handleSubmit = async (e) => {
    e?.preventDefault()
    if (btnClicked)
        return
    setBtnClicked(true)

    const errors = {};
        Object.keys(inputValues).forEach((name) => {
        const error = validateInput(name, inputValues[name]);
        if (error) {
            errors[name] = { hasError: true, errorMessage: error };
        } else {
            errors[name] = { hasError: false, errorMessage: '' };
        }
        });
        setInputErrors(errors);

        let hasError = false;
        Object.keys(errors).forEach((name) => {
        if (errors[name].hasError) {
            hasError = true;
        }
        });

        if (hasError) {
            // There is at least one error
            setBtnClicked(false);
            return;
          }

    try {

        const resp = await loginUser(inputValues)
        console.log(resp)
        if (resp.message=="Wrong password"){
          console.log("wrongpass")
          setInputErrors(prevState => ({
              ...prevState,
              password: {
                  ...prevState.password,
                  hasError: true,
                  errorMessage: "Wrong Password"
              }
          }))
        }
        if (resp.message=="A user with this email could not be found"){
              setInputErrors(prevState => ({
                ...prevState,
                email: {
                    ...prevState.email,
                    hasError: true,
                    errorMessage: "A user with this email doesn't exist"
                }
            }))
        }
        if (resp.message=="Token doesnt exist" && resp.companyadmin){
          console.log("Token doesnt exist")
            navigate('/facebook-signup', { state: { companyId: resp.companyId, message: 'Connect your Facebook Business Account' } })
        }
        if (resp.message=="Token expired" && resp.companyadmin){
          console.log("Token expired")
            navigate('/facebook-signup', { state: { companyId: resp.companyId, message: 'Your Facebook session has expired' } })
        }
        if ((resp.message=="Token doesnt exist" || resp.message=="Token expired") && !resp.companyadmin){
          console.log(resp)
          setInputErrors(prevState => ({
              ...prevState,
              password: {
                  ...prevState.password,
                  hasError: true,
                  errorMessage: "Your Facebook login is expired or incomplete, ask your company admin to login with facebook again"
              }
          }))
        }
        if (!resp.success && resp.error.includes("Cannot read properties")) {
          setInputErrors(prevState => ({
              ...prevState,
              email: {
                  ...prevState.email,
                  hasError: true,
                  errorMessage: "A user with this email doesn't exist"
              }
          }))
        }
        
        if (resp.success) {
            clearInputValues()
            // toast.success('Logged In Successfully')
            localStorage.setItem('token', resp.token)
            localStorage.setItem('userId', resp.userId)
            localStorage.setItem('email', resp.email)
            localStorage.setItem('kycCompleted', resp.isKycDone)
            console.log("setting up local storage ",resp.userId," ",resp.email)
            const remainingMilliseconds = 60 * 60 * 1000;
            const expiryDate = new Date(
                new Date().getTime() + remainingMilliseconds
            );
            localStorage.setItem('expiryDate', expiryDate.toISOString());
            setUserState({ isAuth: true, token: resp.token, userId: resp.userId, email:resp.email,kycCompleted:resp.isKycDone })
            // console.log("response: ",resp.isadmin)
            navigate('/')
        }
        else
            {
                throw new Error(resp.message)
            }
            
    } catch (error) {
    }
    setBtnClicked(false)
    }
    
      return (
        <div className="form-sec">
          <Form onSubmit={handleSubmit}>
            <div className="form-heading text-center">{heading}</div>
            <Form.Group className="form-group">
              <Form.Label>Email Address*</Form.Label>
              <Form.Control type="email" name="email" value={inputValues.email} onChange={handleInputChange} className={inputErrors.email.hasError ? "is-invalid" : ""}/>
              <Form.Control.Feedback type="invalid">
                        {inputErrors.email.hasError && inputErrors.email.errorMessage+'❌'}
                    </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Password*</Form.Label>
              <Form.Control type="password" name="password" value={inputValues.password} onChange={handleInputChange} className={inputErrors.password.hasError ? "is-invalid" : ""}/>
              <Form.Control.Feedback type="invalid">
                        {inputErrors.password.hasError && inputErrors.password.errorMessage+'❌'}
                    </Form.Control.Feedback>
            </Form.Group>
            
            <Form.Group className="form-group">
              <Button className="btn btn-main" type="submit">
                Login
              </Button>
            </Form.Group>
            <Form.Group className="text-center last-pra">
              Don't have an account? <Link to="/signup">Sign up</Link>
            </Form.Group>
          </Form>
        </div>
      );
}

export default Whatsapp_form;