import axios from 'axios'
import config from '../../config'

const baseUrl = `${config.backend}user/`

export const loginUser = async (data) => {
    const response = await axios.post(`${baseUrl}login`, data)
    return response.data
}

export const registerUser = async (data) => {
  const response = await axios.post(`${baseUrl}register`, data)
  return response.data
}

export const getKycCredentials = async(data) => {
  const response = await axios.get(`${baseUrl}getcredentials`)
  return response.data
}

export const getKycDetails = async(data) => {
  const response = await axios.get(`${baseUrl}getkycdetails?id=${data}`)
  return response.data
}