
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

import Logo from "../../images/logos/whatsapp-bot_logo.svg";
import "./header.css";
import { Outlet,useNavigate,useLocation } from 'react-router-dom'

import UserContext from '../../store/user-context';
import { updateUser } from '../../services/User';
import { Button } from 'react-bootstrap';

    const Header = ({ logOutBtnHandler, loggedout=false})=>{
    //const lnks = ['/login','/signup']
    const [currentLink,setCurrentLink] = useState(null)
    const [companyBtn,setCompanyBtn] = useState(true)
    const [profileBtn, setProfileBtn] = useState(false)
    const [userData,setUserData]=useState({})
    const { userState,setUserState } = useContext(UserContext);
    const { userId,updateUser } = userState;
    const [getData,setGetData]=useState(false)
    const [imageUrl, setImageUrl] = useState(null);
    const [pfpExists,setPfpExists]=useState(false)
    const location = useLocation();

    const navigate=useNavigate()

    return(
        <>
            <Navbar expand="lg" className = "Navbar-top">
                <Container fluid>
                    <Navbar.Brand onClick={()=>{navigate('/')}} style={{cursor:'pointer'}} className = "d-none d-lg-block">
                        <img src = {Logo} alt = "logo-main"/>
                    </Navbar.Brand>
                    {!loggedout && <Button onClick={logOutBtnHandler} style={{border:'none',backgroundColor:'white',color:'green'}}>
                        Signout
                    </Button>}
                </Container>
            </Navbar>

            
            <div>
                <Outlet />
            </div>
        </>
    );
}

export default Header;