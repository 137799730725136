// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.user-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.user-name label {
    width: 100%;
    cursor: pointer;
}

.user-name ul li:first-child {
    margin-right: 16px;
}

.user-name ul li .user-img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.user-name ul li .user-img img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.user-name ul li .u-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.user-name .form-check-input{
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.user-name .form-check-input:checked {
    background-color: var(--bg-primary);
    border-color: var(--bg-primary);
}

.edit-group {
    text-decoration: underline;
    color: var(--bg-primary);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/group-table/group-contact.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI,mCAAmC;IACnC,+BAA+B;AACnC;;AAEA;IACI,0BAA0B;IAC1B,wBAAwB;IACxB,eAAe;AACnB","sourcesContent":["\n.user-name {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n}\n\n.user-name label {\n    width: 100%;\n    cursor: pointer;\n}\n\n.user-name ul li:first-child {\n    margin-right: 16px;\n}\n\n.user-name ul li .user-img {\n    width: 44px;\n    height: 44px;\n    border-radius: 50%;\n}\n\n.user-name ul li .user-img img {\n    width: 44px;\n    height: 44px;\n    border-radius: 50%;\n}\n\n.user-name ul li .u-name {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 21px;\n    color: #000000;\n}\n\n.user-name .form-check-input{\n    width: 20px;\n    height: 20px;\n    border-radius: 50% !important;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    cursor: pointer;\n}\n\n.user-name .form-check-input:checked {\n    background-color: var(--bg-primary);\n    border-color: var(--bg-primary);\n}\n\n.edit-group {\n    text-decoration: underline;\n    color: var(--bg-primary);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
